<template>
    <div>
        <div 
            v-show="filterLoading" 
            class="flex justify-center p-2">
            <a-spin />
        </div>
        <div v-show="!filterLoading">
            <div class="f_header flex items-center justify-between pb-2">
                <div class="flex items-center">
                    <div class="flex items-center" v-if="checkExclude">
                        <a-button
                            size="small"
                            class="flex items-center"
                            @click="selectTab(1)"
                            :ghost="activeTab === 1 ? false :true"
                            type="ui">
                            <i class="fi fi-rr-add mr-1"></i>
                            Выбрать {{includeLenght > 0 ? `(${includeLenght})` : ''}}
                        </a-button>
                        <a-button
                            class="ml-1"
                            icon="minus-circle"
                            size="small"
                            @click="selectTab(2)"
                            :ghost="activeTab === 2 ? false :true"
                            type="ui">
                            {{$t('exclude')}} {{excludeLenght > 0 ? `(${excludeLenght})` : ''}}
                        </a-button>
                    </div>
                </div>
            </div>
            <div class="f_body py-4 pr-2">
                <div class="grid grid-cols-1 gap-3">
                    <div v-show="activeTab === 1" v-for="f in filterInclude" :key="f.name">
                        <WidgetsSwicth
                            :filter="f"
                            :page_name="page_name"
                            :windowWidth="windowWidth"
                            :name="name" />
                    </div>
                    <div v-show="activeTab === 2" v-for="f in filterExclude" :key="f.name">
                        <WidgetsSwicth
                            :filter="f"
                            :page_name="page_name"
                            :windowWidth="windowWidth"
                            :name="name" />
                    </div>
                </div>
            </div>
            <div class="filter_footer flex items-center justify-end pt-2">
                <!--<a-button type="ui" @click="resetFilters()">
                    {{$t('reset')}}
                </a-button>
                <a-button 
                    class="ml-2"
                    type="ui"
                    @click="removeFilters()">
                    {{$t('reset')}}
                </a-button>-->
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import eventBus from '@/utils/eventBus'
import computedM from './mixins/computed'
import methodsM from './mixins/methods'
import propsM from './mixins/props'
import WidgetsSwicth from './widgets/WidgetsSwicth'
export default {
    components: {
        WidgetsSwicth
    },
    mixins: [
        methodsM,
        computedM,
        propsM
    ],
    data() {
        return {
            visible: false,
            filterLoading: false,
            tags: [],
            first: true,
            input: '',
            name: this.model,
            activeTab: 1,
            filterInclude: [],
            filterExclude: [],
            searchLoading: false,
            filterIncludeData: null
        }
    },
    created() {
        this.filterInit()
    },
    methods: {
        ...mapActions({
            getFiltersByKey: "filter/getFiltersByKey"
        }),
        filterItemKey(filter, index) {
            switch (filter.field) {
            case 'array':
                return filter.value?.[0]?.id ? filter.value?.[0]?.code ? `${filter.value[0].code}_${filter.name}` : `${filter.value[0].id}_${filter.name}` : `${filter.name}_${filter.field}`
                break;
            default:
                return index
            }
        },
        async filterInit() {
            try {
                this.filterLoading = true
                this.$store.commit('filter/SET_FILTER_LOADING', {
                    name: this.page_name,
                    value: true
                })
                this.name = this.page_name
                
                if(!this.checkLoaded){ 
                    await this.getFiltersByKey(
                        {
                            name: this.model,
                            page_name: this.page_name,
                            params:  this.queryParams,
                            excludeFields: this.excludeFields
                        })
                } 
                this.setFilterData()
                await this.tagGenerate()
            } catch(e) {
                console.log(e)
            } finally {
                this.$store.commit('filter/SET_FILTER_LOADING', {
                    name: this.page_name,
                    value: false
                })
                this.filterLoading = false
            }
        }
    },
    mounted() {
        eventBus.$on(`send_include_fields_${this.page_name}`, data => {
            this.filterIncludeData = data
            this.setFilter()
        })
        eventBus.$on(`set_include_data_${this.page_name}`, data => {
            this.filterIncludeData = data
        })
    },
    beforeDestroy() {
        eventBus.$off(`send_include_fields_${this.page_name}`)
        eventBus.$off(`set_include_data_${this.page_name}`)
    }
}
</script>

<style lang="scss">
.filter_item{
    &:not(:last-child){
        margin-bottom: 15px;
    }
}
</style>